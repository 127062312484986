import 'cookieconsent'
import Cookies from 'js-cookie'

var i18n = window.i18n

window.cookieconsent.initialise({
  "container": document.getElementById("cookieconsent"),
  "palette": {
      "popup": {
          "background": "#252e39"
      },
      "button": {
          "background": "#14a7d0"
      }
  },
  "type": "opt-out",
  revokable: true,
  onRevokeChoice: revokeConsent,
  "content": {
    message: i18n.gettext("This website uses cookies to ensure you get the best experience on our website."),
    allow: i18n.gettext('Allow cookies'),
    deny: i18n.gettext('Decline'),
    link: i18n.gettext('Learn more'),
    "href": "/privacy/",
  }
});

function revokeConsent(consent) {
  // We clear Google's cookies here. Note that attempting to disable Google Analytics by setting
  // 'window['ga-disable-[tracking id]'] = true' will not work here because it must be done before it's loaded.
  //
  // This doesn't matter because Google won't know who the user is when it receives the calls.
  Cookies.remove('_ga')
  Cookies.remove('_gid')
  Cookies.remove('_gat')
}
