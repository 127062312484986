import $ from 'jquery'
import 'css/lang.sass'

$('#esa-language').on('click', function(e) {
  e.preventDefault()
  e.stopPropagation()

  $('.quick-lang').toggleClass('open')
  // To avoid overlying the language and search dialogs,
  // ensure that search dropdown is closed
  $('.quick-search').removeClass('open')

  $(document).on('click', function closeMenu(closeClick) {
    if ($('.quick-lang').has(closeClick.target).length === 0) {
      $('.quick-lang').removeClass('open')
    } else {
      $(document).on('click', closeMenu)
    }
  });
});