// This only gives us non-SVG icons.
//import '@fortawesome/fontawesome-free'


// import { library, icon, dom, config } from '@fortawesome/fontawesome-svg-core'
import { library, dom, config } from '@fortawesome/fontawesome-svg-core'
// We need to use the Pro ones here.
import {
    faBars, faSearch, faHome, faAngleDoubleUp, faArrowRight, faArrowLeft, faTimes, faChevronRight, faChevronLeft,
    faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faArrowsAlt, faCalendarAlt, faCalendarDay, faClock, faFileDownload, faMapMarkerAlt, faRedo,
    faFlag, faUser, faTag, faFolderOpen
    } from '@fortawesome/free-solid-svg-icons'
// import {  } from '@fortawesome/free-regular-svg-icons'
import { faYoutube, faTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
config.autoReplaceSvg = true
library.add(
    faBars, faSearch, faHome, faAngleDoubleUp, faArrowRight, faArrowLeft, faTimes, faChevronRight, faChevronLeft,
    faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faArrowsAlt, faCalendarAlt, faCalendarDay, faClock, faFileDownload, faMapMarkerAlt, faRedo,
    faFlag, faUser, faTag, faFolderOpen, faYoutube, faTwitter, faFacebookF, faInstagram
    )
dom.i2svg()



// import { faHomeLgAlt, } from '@fortawesome/free-solid-svg-icons'
// import { faBars, faSearch, faArrowRight, faArrowLeft, faTimes } from '@fortawesome/free-regular-svg-icons'
// import { faYoutube, faTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
