import $ from 'jquery'
import 'css/search.sass'

$('#esa-search').on('click', function(e) {
  e.preventDefault()
  e.stopPropagation()

  $('.quick-search').toggleClass('open')
  $('#quicksearch-i').focus()
  // To avoid overlying the search and language dialogs,
  // ensure that language dropdown is closed
  $('.quick-lang').removeClass('open')

  $(document).on('click', function closeMenu(closeClick) {
    if ($('.quick-search').has(closeClick.target).length === 0) {
      $('.quick-search').removeClass('open')
    } else {
      $(document).on('click', closeMenu)
    }
  });
});
