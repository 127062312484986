// Move bootstrap here? We can if we separate CSS with mini-css-extract-plugin
// import 'monitoring.js' // is this meant to be on the home page only or all pages
// isotope.pkgd.min.js?
// bootstrap JS?

// The CSS needs to come just after tidy.css - but maybe we can get the JS only to load here? (TODO?)
//import 'bootstrap'


//import 'css/lowprio.sass'

// Do we need classie or isotope? Classie appears obsolete. isotope is commercial - we should avoid it or pay.

// import '@fortawesome/fontawesome-free'
// import '@fortawesome/fontawesome-free/js/all.js';

import 'fontawesome.js'


import 'search.js'
import 'language.js'
import 'ccikecookieconsent.js'

import 'external-links.js'
import 'equalise-cards.js'

import $ from 'jquery';
import { Tab } from 'bootstrap';

// Function to activate tab based on fragment identifier
function activateTabFromFragment() {
    // Get the fragment identifier from the URL
    var fragment = window.location.hash;

    // Find the tab link corresponding to the fragment identifier
    var tabLink = $('a[href="' + fragment + '"]');

    // Check if the tab link exists
    if (tabLink.length > 0) {
        // Trigger click event on the tab link
        tabLink.tab('show');
    }

    if (fragment.length > 0) {
        // Find the tab content element with the id corresponding to the fragment
        var tabContent = document.querySelector(fragment);

        // Check if the tab content element exists
        if (tabContent) {
            // Create or get the Tab instance for the tab content element and show it
            Tab.getOrCreateInstance(tabContent).show();
        }
    }


}

// Activate tab on page load
activateTabFromFragment();