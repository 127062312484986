import $ from 'jquery'

var minHeight = parseInt(0);
$(".card-item").each(function () {
  if ($(this).outerHeight() > minHeight) {
    minHeight = $(this).outerHeight();
  }
});

var eventsMinHeight = parseInt(0);
$(".events-card-item").each(function () {
  if ($(this).outerHeight() > eventsMinHeight) {
    eventsMinHeight = $(this).outerHeight();
  }
});

var promotedMinHeight = parseInt(0);
$(".promoted-item").each(function () {
  if ($(this).outerHeight() > promotedMinHeight) {
    promotedMinHeight = $(this).outerHeight();
  }
});

var highlightsMinHeight = parseInt(0);
$(".highlights-buttom").each(function () {
  if ($(this).outerHeight() > highlightsMinHeight) {
    highlightsMinHeight = $(this).outerHeight();
  }
});

$('.card-item').css('height', minHeight);

$('.events-card-item').css('height', eventsMinHeight);

$('.promoted-item').css('height', promotedMinHeight);

$('.highlights-buttom').css('height', highlightsMinHeight);